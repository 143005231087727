/* @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-responsive-modal'
import { Grid, Heading, jsx, Text, useThemeUI } from 'theme-ui'
import FadeInWrapper from '../../Generic/FadeInWrapper'
import ProductOutOfStock from '../OutOfStock'
import { GridItemPropType, ProductGridItem } from './GridItem'

const gridStyle = {
  placeItems: 'center',
  position: 'relative',
  maxWidth: 1440,
  width: 'max(75vw, 100%)',
  margin: 'auto',
  gridTemplateRows: '392px',
  gap: 0
}

export const ProductGrid = ({ data, node_locale }) => {
  //#region Hooks
  const matchMedia = useRef(
    typeof window !== 'undefined'
      ? window.matchMedia('(max-width: 900px)')
      : null
  )
  const {
    title,
    featuredVariant,
    subtitle,
    variants,
    hideFeaturedVariant,
    showAllGridItemsOnMobile
  } = data
  const maxGridCols = hideFeaturedVariant ? 3 : 4
  const [isMobile, setIsMobile] = useState(matchMedia.matches ?? false)
  const [mobileVariants] = useState(
    showAllGridItemsOnMobile ? variants : variants.slice(0, 3)
  )
  const [waitlistPopup, setWaitlistPopup] = useState()
  const [modalOpen, setModalOpen] = useState()
  const {
    theme: { rawColors }
  } = useThemeUI()

  // TODO -- see what this exactly is doing
  useEffect(() => {
    if (!matchMedia.current) return
    setIsMobile(matchMedia.current.matches)
    matchMedia.current.addEventListener('change', onMatchMediaChange)

    return () => {
      matchMedia.current.removeEventListener('change', onMatchMediaChange)
    }
  }, [matchMedia.current])

  const onMatchMediaChange = () => {
    setIsMobile(matchMedia.current.matches)
  }

  const onGetItem = async sku => {
    await window.dkAddToCart(sku, 1)
  }

  const onItemOutOfStock = item => {
    setWaitlistPopup({ ...item.waitlistPopup, sku: item.sku })
    setModalOpen(true)
  }

  const onCloseModal = () => {
    setWaitlistPopup(null)
    setModalOpen(false)
  }

  const DesktopGrid = (
    <>
      <Grid
        sx={{
          ...gridStyle,
          gridTemplateColumns: `repeat(${maxGridCols}, 1fr)`,
          rowGap: hideFeaturedVariant ? '64px' : undefined,
          paddingTop: [32, 44],
          paddingX: ['12px', 39]
        }}
      >
        {(hideFeaturedVariant ? variants : variants.slice(0, 4)).map(item => (
          <ProductGridItem
            sticker={item.productGridSticker}
            locale={node_locale}
            key={item.sku}
            item={item}
            onClick={onGetItem}
            onNoAvailability={onItemOutOfStock}
          />
        ))}
      </Grid>
      {!hideFeaturedVariant && (
        <Grid
          sx={{
            ...gridStyle,
            justifyContent: 'center',
            width: '100%',
            gridTemplateColumns: `repeat(${maxGridCols}, 1fr)`,
            rowGap: hideFeaturedVariant ? '64px' : undefined,
            paddingTop: [32, 44],
            paddingX: ['12px', 39]
          }}
        >
          <ProductGridItem
            isFeatured
            locale={node_locale}
            item={featuredVariant}
            sticker={featuredVariant.productGridSticker}
            onClick={onGetItem}
            onNoAvailability={onItemOutOfStock}
          />

          {variants.slice(4).map(item => (
            <ProductGridItem
              sticker={item.productGridSticker}
              locale={node_locale}
              key={item.sku}
              item={item}
              onClick={onGetItem}
              onNoAvailability={onItemOutOfStock}
            />
          ))}
        </Grid>
      )}
    </>
  )

  const MobileGrid = (
    <>
      <Grid
        gap={0}
        sx={{
          width: '100%',
          margin: 'auto',
          gridTemplateColumns: 'repeat(2, 45%)',
          rowGap: '48px',
          columnGap: 3,
          mb: '48px',
          placeItems: 'center',
          justifyItems: 'center',
          justifyContent: 'center'
        }}
      >
        {mobileVariants.map((item, i) => (
          <ProductGridItem
            sticker={item.productGridSticker}
            locale={node_locale}
            key={item.sku}
            item={item}
            onClick={onGetItem}
            onNoAvailability={onItemOutOfStock}
          />
        ))}
      </Grid>
      {!hideFeaturedVariant && (
        <ProductGridItem
          limit
          sticker={featuredVariant.productGridSticker}
          locale={node_locale}
          item={featuredVariant}
          isFeatured
          onClick={onGetItem}
          onNoAvailability={onItemOutOfStock}
        />
      )}
    </>
  )

  return (
    <div
      sx={{
        backgroundColor: 'frenchPass',
        pt: ['22px', '84px'],
        pb: ['48px', '96px']
      }}
    >
      <FadeInWrapper>
        <Heading
          variant="text.h2"
          color="governorBay"
          sx={{
            textAlign: 'center',
            mb: 24,
            textTransform: 'uppercase',
            fontSize: ['32px', '60px'],
            lineHeight: ['32px', '60px']
          }}
        >
          {title}
        </Heading>
        <Text
          color="governorBay"
          as="p"
          sx={{
            textAlign: 'center',
            color: 'governorBay',
            maxWidth: 540,
            width: '90%',
            mx: 'auto',
            mb: 64,
            fontSize: 18,
            lineHeight: ['22px', '24px']
          }}
        >
          {subtitle.childMarkdownRemark.excerpt}
        </Text>
      </FadeInWrapper>
      {isMobile ? MobileGrid : DesktopGrid}
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        styles={{
          modal: {
            background: rawColors.accent,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: rawColors.tertiary,
            color: rawColors.tertiary,
            borderRadius: 8
          },
          closeIcon: {
            fill: rawColors.tertiary
          }
        }}
        center
      >
        <div
          sx={{
            width: '100%',
            maxWidth: 582,
            position: 'relative',
            background: 'transparent'
          }}
        >
          {waitlistPopup?.heading ? (
            <Heading variant="text.h5"> {waitlistPopup.heading}</Heading>
          ) : null}
          {waitlistPopup?.body?.body ? (
            <Text>{waitlistPopup.body.body}</Text>
          ) : null}
        </div>
        <ProductOutOfStock sku={waitlistPopup?.sku || ''} />
      </Modal>
    </div>
  )
}

ProductGrid.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        excerpt: PropTypes.string.isRequired
      })
    }),
    variants: PropTypes.arrayOf(GridItemPropType),
    bestSeller: GridItemPropType,
    featuredVariant: GridItemPropType
  }).isRequired
}
