import { graphql } from 'gatsby'
import React from 'react'
import Home from '~/components/Home/index'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const IndexPage = ({ data }) => {
  return data && data.home ? (
    <Layout>
      <Metadata title={data.home.title} />
      <Home page={data.home}></Home>
    </Layout>
  ) : null
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulHome(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      id
      slug
      node_locale
      title
      heading
      subheading

      heroCallToAction {
        ...CallToActionFragment
      }

      productGrid {
        title
        showAllGridItemsOnMobile
        hideFeaturedVariant
        subtitle {
          childMarkdownRemark {
            excerpt
          }
        }
        variants {
          sku
          size
          price
          productGridSticker {
            title
            fill
            stroke
          }
          productGridImage {
            fixed(quality: 90) {
              ...GatsbyContentfulFixed_noBase64
            }
          }
          product {
            name
            slug
            node_locale
            waitlistPopup {
              heading
              body {
                body
              }
            }
            images {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
        featuredVariant {
          sku
          size
          price
          productGridSticker {
            title
            fill
            stroke
          }
          productGridImage {
            fixed(quality: 90) {
              ...GatsbyContentfulFixed_noBase64
            }
          }
          product {
            slug
            name
            productType {
              slug
            }
            images {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      categorySelection {
        internal {
          type
        }
        name
        slug
        mainImage {
          id
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      quoteSectionTitle
      quoteCards {
        id
        attributionName
        attributionTitle
        quote
        photo {
          id
          title
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      articleHighlightList {
        ...ArticleCardFragment
      }
      pageSections {
        name
        slug
        title
        images {
          id
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
          file {
            details {
              image {
                width
              }
            }
          }
        }
        testimonials {
          id
          quote
          title
          image {
            id
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
            file {
              url
              details {
                image {
                  width
                }
              }
            }
          }
        }
      }
      pageHighlightList {
        ...TextSectionWithButton
      }
      heroVideoFallback {
        id
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImage {
        id
        fluid(maxWidth: 2000, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        file {
          url
        }
        title
      }
      mobileHeroVideo {
        id
        fluid(maxWidth: 2000, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        file {
          url
        }
        title
      }
      bowlImage {
        id
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      maskedVideo {
        id
        title
        file {
          url
        }
      }
      valuePropHeading
      valuePropSubheading
      valuePropImage {
        valuePropImage {
          title
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      productHighlightList {
        ...TextSectionWithButton
      }
      generalHighlight {
        ...TextSectionWithButton
      }
      marqueeCopy
      marqueeIcons {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      videoTestimonialTitle
      videoTestimonials {
        ...VideoTestimonial
      }
    }
  }
`
