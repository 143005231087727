import React from 'react'
import MarqueeContainer from 'react-fast-marquee'
import { Box, Grid, Text } from 'theme-ui'
import BouncyIngredientIcon from '~/components/Home/BouncyIngredientIcon'
import { useWindowSize } from 'react-use'

const Marquee = ({ page: { marqueeCopy = [], marqueeIcons = [] } }) => {
  const length = Math.max(marqueeCopy.length, marqueeIcons.length)
  const marqueContent = Array.from({ length }, (_, i) => ({
    copy: marqueeCopy[i],
    icon: marqueeIcons[i],
  }))

  const { width } = useWindowSize()

  return (
    <Box
      sx={t => ({
        background: t.colors.robinsEggBlue,
        pt: '2rem',
        pb: '1rem',
        height: ['7rem', '10rem'],
      })}
    >
      <MarqueeContainer
        gradient={false}
        speed={35}
        style={{
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {marqueContent.map(({ copy, icon }) => (
          <Grid
            key={copy}
            sx={{
              px: ['0.625rem', '1.25rem'],
              gridTemplateColumns: 'max-content minmax(100px, 1fr)',
              alignItems: 'center',
              gap: ['1.2rem', '2.5rem'],
            }}
          >
            <Text
              sx={{
                fontFamily: 'monospace',
                fontSize: ['1.5rem', '2rem'],
                fontWeight: 'extraBold',
                lineHeight: 1,
                color: 'governorBay',
                whiteSpace: 'nowrap',
              }}
            >
              {copy}
            </Text>
            <BouncyIngredientIcon
              imgObj={icon}
              imgStyle={{ mt: '30px' }}
              forwardSx={{
                width: ['4rem', '4rem'],
                height: ['4rem', '4rem'],
                mt: '1rem',
              }}
              distance={width >= 678 ? 15 : 7}
            />
          </Grid>
        ))}
      </MarqueeContainer>
    </Box>
  )
}

export default Marquee
