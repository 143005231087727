/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { jsx, Flex, Button, Text } from 'theme-ui'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import useOnClickOutside from '~/hooks/components/use-on-click-outside'
import Spinner from '~/components/Generic/Spinner'

const RedeemGiftCardModal = ({ setGiftModalOpen }) => {
  const { login } = useAuth()
  const translate = useTranslate()
  const [isLoading, setIsLoading] = useState(false)
  const modalRef = useRef()
  const onLogin = async () => {
    try {
      setIsLoading(true)
      await login({
        email: window.location.pathname.split('/')[2],
        showUI: true,
        redirectURI: `${process.env.GATSBY_SERVER_URL}/account`,
      })
      setIsLoading(false)
    } catch (error) {
      setGiftModalOpen(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = 'hidden'
    }, 2000)
  }, [])

  useOnClickOutside(modalRef, () => {
    if (!isLoading) {
      setGiftModalOpen(false)
    }
  })

  return (
    <Flex
      sx={{
        position: 'fixed',
        height: '110vh',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 0,
        zIndex: 9998,
        padding: 0,
        overflow: 'hidden',
        maxHeight: '100%',
      }}
    >
      <Flex
        ref={modalRef}
        sx={{
          width: ['90%', '50%', '50%'],
          height: 'auto',
          border: '2px solid yellow',
          flexDirection: 'column',
          backgroundColor: 'green',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          padding: '24px',
          fontSize: [9, 10, 11],
          textAlign: 'center',
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Text
              sx={{
                width: '90%',
                mb: '24px',
                fontSize: '1.5rem',
                color: 'yellow',
              }}
              dangerouslySetInnerHTML={{
                __html: translate('gift_card.modal_body'),
              }}
            />
            <Button
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                width: '125px',
              }}
              onClick={async () => onLogin()}
            >
              {translate('gift_card.redeem')}
            </Button>{' '}
          </>
        )}
      </Flex>
    </Flex>
  )
}
export default RedeemGiftCardModal
