/** @jsx jsx */
import Img from 'gatsby-image'
import { Grid, jsx, Text } from 'theme-ui'
import { keyframes } from '@emotion/react'

const floatAnimObj = (distance, duration) => {
  const float = keyframes`
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, -${distance}px); }
      100%   { transform: translate(0, -0px); }
      `
  return {
    animation: `${float} ${duration}ms infinite ease-in-out`
  }
}

function BouncyIngredientIcon({
  justifySelf,
  imgObj,
  imgStyle,
  forwardSx,
  distance = 15
}) {
  return (
    <div
      sx={{
        width: 'fit-content',
        justifySelf: ['center', justifySelf]
      }}
    >
      <Grid
        sx={{
          margin: 'auto',
          ...floatAnimObj(distance, 6000 + Math.random() * 1000),
          ...forwardSx
        }}
      >
        <Img
          imgStyle={{ objectFit: 'contain', ...imgStyle }}
          fluid={imgObj.fluid}
        />
        <Text variant="text.eyebrow">{imgObj.title}</Text>
      </Grid>
    </div>
  )
}

export default BouncyIngredientIcon
