/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, Flex, Text, Heading } from 'theme-ui'
import YellowStar from '~/assets/images/icons/yellow-star.svg'
import UsWeekly from '~/assets/images/logos/us-weekly-logo.svg'
import Uproxx from '~/assets/images/logos/uproxx-logo.svg'
import Shape from '~/assets/images/logos/shape-logo.svg'

function PressCopyAndQuotes({ section: { title, testimonials } }) {
  const [activeTestimonialIdx, setActiveTestimonialIdx] = useState(0)

  // Clearing the timeout fixes the hot reload issue, it looked like a mem leak.
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeTestimonialIdx < 2) {
        setActiveTestimonialIdx(x => x + 1)
      } else {
        setActiveTestimonialIdx(0)
      }
    }, 3000)
    return () => clearTimeout(timeout)
  }, [activeTestimonialIdx])

  return (
    <>
      {/* Header & reviews */}
      <Flex
        sx={{
          flexDirection: 'column',
          textAlign: ['center', 'left'],
          width: ['80%', '40%'],
          maxWidth: '527px',
          minWidth: '300px',
          mt: ['25px', 0],
        }}
      >
        <Heading
          as="h2"
          sx={{
            textTransform: 'uppercase',
            lineHeight: ['2rem', '3rem'],
            fontSize: ['2rem', '3rem'],
          }}
        >
          {title}
        </Heading>
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: 'center',
            mt: ['24px', '20px'],
            mb: ['40px', 0],
          }}
        >
          <Flex
            sx={{
              mb: ['8px', 0],
              alignItems: 'center',
              '> svg': {
                mr: '8px',
              },
            }}
          >
            <YellowStar />
            <YellowStar />
            <YellowStar />
            <YellowStar />
            <YellowStar />
          </Flex>
          <Text sx={{ fontSize: ['0.8rem', '1rem'] }}>
            4.8 / 5.0 (10,200 reviews and counting!)
          </Text>
        </Flex>
      </Flex>
      {/* Testimonials */}
      <Flex
        sx={{
          flexDirection: 'column',
          width: ['80%', '40%'],
          maxWidth: '527px',
          minHeight: ['none', '190px'],
          justifyContent: 'center',
        }}
      >
        <Text
          sx={{
            mb: '40px',
            textAlign: 'center',
            minHeight: ['72px', '48px'],
          }}
        >
          {testimonials[activeTestimonialIdx].quote}
        </Text>
        <Flex
          sx={{
            justifyContent: 'center',
            svg: {
              cursor: 'pointer',
              width: '60px',
              height: ['60px'],
              transition: 'all 0.2s ease-in-out',
            },
            'svg:not(:last-child)': { mr: '35px' },
          }}
        >
          <Shape
            sx={{ opacity: activeTestimonialIdx === 0 ? 1 : 0.5 }}
            onClick={() => setActiveTestimonialIdx(0)}
          />
          <Uproxx
            fill="#FFDD00"
            sx={{ opacity: activeTestimonialIdx === 1 ? 1 : 0.5 }}
            onClick={() => setActiveTestimonialIdx(1)}
          />
          <UsWeekly
            sx={{ opacity: activeTestimonialIdx === 2 ? 1 : 0.5 }}
            onClick={() => setActiveTestimonialIdx(2)}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default PressCopyAndQuotes
