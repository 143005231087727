/** @jsx jsx */
import React, { useState } from 'react'
import { Box, Flex, Text, jsx } from 'theme-ui'
import VideoPlayer from './VideoPlayer'

const VideoTestimonialItem = ({ testimonial = {}, isActive, isVisible }) => {
  const { handle, quote, video, colorTheme } = testimonial

  const [isPlaying, setIsPlaying] = useState(false)

  const handleMouseEnter = () => {
    setIsPlaying(true)
  }
  const handleMouseLeave = () => {
    setIsPlaying(false)
  }

  return (
    <Box
      sx={{
        borderRadius: '1.5rem',
        overflow: 'hidden',
        position: 'relative',
        maxWidth: ['15rem', null, '21.125rem'],
        isolation: 'isolate',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex
        sx={{
          pt: '2.5rem',
          justifyContent: 'center',
          height: '10rem',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          background: `linear-gradient(180deg, #FFC8D0 0%, rgba(255, 200, 208, 0) 100%),
             linear-gradient(180deg, #FFC8D0 0%, rgba(255, 200, 208, 0) 100%)`,
        }}
      >
        <Text
          sx={{
            fontFamily: 'heading',
            fontSize: ['1.25rem', null, '1.5rem'],
            lineHeight: 1.2,
            color: 'sunsetOrange',
          }}
        >
          {handle}
        </Text>
      </Flex>
      <VideoPlayer
        url={video.file.url}
        isActive={isActive}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        loop={false}
        isVisible={isVisible}
      />
      {!!quote && (
        <Box
          sx={{
            variant: `cards.testimonials.${colorTheme}`,
            position: 'absolute',
            bottom: ['.5rem', null, '1rem'],
            left: ['.5rem', null, '1rem'],
            right: ['.5rem', null, '1rem'],
            p: ['1rem', null, '1.5rem'],
            borderRadius: '1.5rem',
            textAlign: 'center',
            fontFamily: 'heading',
            fontSize: ['1rem', null, '1.5rem'],
            lineHeight: 1,
            fontWeight: 900,
            textTransform: 'capitalize',
            // ...colorThemes[colorTheme],
            '& p': { my: 0 },
          }}
          dangerouslySetInnerHTML={{ __html: quote.childMarkdownRemark?.html }}
        />
      )}
    </Box>
  )
}

export default VideoTestimonialItem
