/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import FadeInWrapper from '../Generic/FadeInWrapper'
import TextSectionWithButton from '../Generic/TextSectionWithButton'
import Img from 'gatsby-image'
import MaskedVideo from '../Generic/MaskedVideo'
import useMatchMedia from '~/hooks/utils/use-match-media'

const GeneralHighlightSection = ({ page: { generalHighlight = {} } }) => {
  const {
    flipped,
    heading,
    image,
    subheading,
    desktopVideo,
    mobileVideo,
    callToAction,
  } = generalHighlight
  const childrenBeforeButton = useMatchMedia('(max-width: 768px)')

  return (
    <TextSectionWithButton
      cta={callToAction}
      flipped={flipped}
      headingText={heading}
      subheadingText={subheading}
      childrenBeforeButton={childrenBeforeButton}
      containerStyle={{
        backgroundColor: 'schoolBusYellow',
        color: 'governorBay',
        width: '100%',
        px: ['2rem', 0],
        gridTemplateColumns: [
          '1fr',
          'repeat(2, minmax(min-content, 32.25rem))',
        ],
        justifyContent: ['center', 'center'],
        alignItems: 'center',
        gap: '2.5rem',
        '& h2': {
          fontSize: ['2.75rem', '6.25rem'],
          lineHeight: 0.8,
        },
        '& p': {
          fontSize: ['1.125rem', '1.25rem'],
          maxWidth: '27rem',
          lineHeight: 1.2,
        },
      }}
      buttonStyle={{
        backgroundColor: 'governorBay',
        color: 'schoolBusYellow',
        mx: 'auto',
        mt: '2rem',
      }}
    >
      {image ? (
        <FadeInWrapper>
          <Img fluid={image.fluid} />
        </FadeInWrapper>
      ) : (
        <>
          <FadeInWrapper forwardSx={{ display: ['none', 'block'] }}>
            <MaskedVideo video={desktopVideo ?? mobileVideo} id="1" />
          </FadeInWrapper>
          <FadeInWrapper forwardSx={{ display: ['block', 'none'] }}>
            <MaskedVideo video={mobileVideo ?? desktopVideo} id="2" />
          </FadeInWrapper>
        </>
      )}
    </TextSectionWithButton>
  )
}

export default GeneralHighlightSection
