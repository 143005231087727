/** @jsx jsx */

import { Grid, jsx, Heading, Paragraph, Text, Box } from 'theme-ui'
import FadeInWrapper from '~/components/Generic/FadeInWrapper'
import { LinkWrapper } from '~/components/Product/Page/LinkWrapper'

function TextSectionWithButton({
  containerStyle,
  flipped,
  headingText,
  subheadingText,
  statementHeadingText,
  buttonStyle,
  cta,
  children,
  childrenBeforeButton = false,
}) {
  return (
    <Grid
      sx={{
        paddingY: 50,
        paddingX: [25, 100],
        justifyContent: ['center', 'initial'],
        justifyItems: ['center', 'initial'],
        textAlign: ['center', 'initial'],
        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
        ...containerStyle,
      }}
    >
      <Box
        sx={{
          alignSelf: 'center',
          order: [1, flipped ? -1 : 1],
        }}
      >
        <FadeInWrapper>
          {statementHeadingText && (
            <Heading
              sx={{
                mb: ['1rem', '1.5rem'],
                textTransform: 'uppercase',
                fontFamily: 'secondary',
                fontWeight: 700,
                fontSize: '0.825rem',
                lineHeight: 0.86,
                letterSpacing: '0.0075em',
              }}
            >
              {statementHeadingText}
            </Heading>
          )}
          <Heading
            variant="text.h3"
            sx={{
              fontSize: ['2.5rem', '3.7rem'],
            }}
            dangerouslySetInnerHTML={{ __html: headingText }}
          />
        </FadeInWrapper>
        <FadeInWrapper>
          <Paragraph
            sx={{
              marginY: 30,
            }}
          >
            {subheadingText}
          </Paragraph>
        </FadeInWrapper>
        {childrenBeforeButton && children}

        {cta && (
          <FadeInWrapper>
            <LinkWrapper
              slug={cta?.page?.slug}
              contenttype={cta?.page?.__typename}
              sx={{
                variant: 'buttons.primary',
                px: '1.125rem',
                py: '1rem',
                minWidth: '10rem',
                display: 'flex',
                aligItems: 'center',
                justifyContent: 'center',
                ...buttonStyle,
              }}
            >
              <Text>{cta.text}</Text>
            </LinkWrapper>
          </FadeInWrapper>
        )}
      </Box>
      {!childrenBeforeButton && (
        <Box id="after" sx={{ width: '100%' }}>
          {children}
        </Box>
      )}
    </Grid>
  )
}

export default TextSectionWithButton
