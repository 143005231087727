/** @jsx jsx */
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, Heading, jsx } from 'theme-ui'
import VideoTestimonialItem from './Item'

const VideoTestimonialsSection = ({
  page: { videoTestimonials, videoTestimonialTitle }
}) => {
  return (
    <Box
      sx={{
        px: [0, '4rem', '8rem'],
        py: '6rem',
        mx: 'auto'
      }}
    >
      <Heading
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          color: 'pink',
          fontSize: ['2rem', '3.725rem'],
          mb: ['2.5rem', null, '5rem']
        }}
      >
        {videoTestimonialTitle}
      </Heading>
      <Box
        sx={{
          display: ['none', 'none', 'flex'],
          justifyContent: 'center',
          gap: '2.5rem'
        }}
      >
        {videoTestimonials.map((testimonial, index) => (
          <VideoTestimonialItem
            key={`${testimonial.id}-${index}`}
            testimonial={testimonial}
            index={index}
          />
        ))}
      </Box>
      <Box sx={{ variant: 'responsive.mobileTablet' }}>
        <Swiper
          slidesPerView={1.6}
          centeredSlides={true}
          spaceBetween={16}
          loop={true}
          watchSlidesProgress={true}
          breakpoints={{
            400: { slidesPerView: 1.8 },
            450: { slidesPerView: 2 },
            500: { slidesPerView: 2.2 },
            550: { slidesPerView: 2.4 },
            600: { slidesPerView: 2.6 },
            650: { slidesPerView: 2.8 },
            700: { slidesPerView: 3 }
          }}
        >
          {videoTestimonials.map(testimonial => (
            <SwiperSlide key={testimonial.id}>
              {({ isActive, isVisible }) => (
                <VideoTestimonialItem
                  testimonial={testimonial}
                  isActive={isActive}
                  isVisible={isVisible}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default VideoTestimonialsSection
