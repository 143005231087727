/** @jsx jsx */
import Img from 'gatsby-image'
import { Grid, jsx } from 'theme-ui'
import FadeInWrapper from '~/components/Generic/FadeInWrapper'
import TextSectionWithButton from '~/components/Generic/TextSectionWithButton'

const PageHighlightsSection = ({
  page: { pageHighlightList },
  homePageButtonStyle,
}) => {
  return (
    <div sx={{ color: 'sunsetOrange', backgroundColor: 'pink', py: 90 }}>
      <Grid sx={{ justifyContent: 'center' }}>
        {pageHighlightList.map((highlight, i) => {
          return (
            <TextSectionWithButton
              headingText={highlight.heading}
              subheadingText={highlight.subheading}
              cta={highlight.callToAction}
              buttonStyle={{
                mx: 'auto',
                ...homePageButtonStyle,
              }}
              flipped={highlight.flipped}
              containerStyle={{ gridGap: 60 }}
              key={`${highlight.heading}_${i}}`}
            >
              <FadeInWrapper>
                <Img fluid={highlight.image.fluid} />
              </FadeInWrapper>
            </TextSectionWithButton>
          )
        })}
      </Grid>
    </div>
  )
}

export default PageHighlightsSection
