/** @jsx jsx */
import Img from 'gatsby-image'
import { jsx, useThemeUI, Container } from 'theme-ui'
import ImageAndTextSection from '~/components/Product/Page/ImageAndTextSection'
import { WigglySectionDivider } from '~/components/Generic/SectionDivider/WigglySectionDivider'
import { MobileWigglySectionDivider } from '~/components/Generic/SectionDivider/MobileWigglySectionDivider'

import useMatchMedia from '~/hooks/utils/use-match-media'

const HeroSection = ({
  page: {
    heading,
    subheading,
    heroImage,
    heroVideoFallback,
    localButtonStyle,
    mobileHeroVideo,
    heroCallToAction,
  },
}) => {
  const { theme } = useThemeUI()
  const isMobile = useMatchMedia('(max-width: 768px)')
  const mobileVideo = !!mobileHeroVideo ? mobileHeroVideo : heroVideoFallback
  const heroFallBackIsVideo =
    heroVideoFallback.file?.contentType.startsWith('video')
  return (
    <Container
      p={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: ['center', 'flex-start'],
        width: '100%',
        paddingX: '0 !important',
        position: 'relative',
        height: ['55vh', '92vh', '720px'],
        backgroundColor: isMobile ? '#69DCC9' : theme.colors.frenchPass,
      }}
    >
      <ImageAndTextSection
        cta={heroCallToAction}
        fluid={heroImage.fluid}
        headingText={heading}
        subHeadingText={subheading}
        containerStyle={{
          display: ['none', 'flex'],
          mt: ['125px', '127px'],
          color: 'japaneseLaurel',
          paddingBottom: [0, 0, 120],
          maxWidth: 1440,
          width: ['auto', (626 + 50) * 2],
          zIndex: 2,
        }}
        buttonStyle={{
          width: '100%',
          maxWidth: '320px',
          ...localButtonStyle,
        }}
      />
      {/* Desktop video */}
      <div
        sx={{
          position: 'absolute',
          width: '100%',
          height: '102%',
          left: 0,
          bottom: 0,
        }}
      >
        <video
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: '90%',
            display: ['none', 'block'],
          }}
          src={
            !heroFallBackIsVideo
              ? `https:${heroImage.file.url}`
              : isMobile
              ? `https:${heroVideoFallback.file.url}`
              : `https:${heroImage.file.url}`
          }
          autoPlay
          playsInline
          loop
          muted
          controls={false}
        />
      </div>
      {/* Mobile Video */}
      <div
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <video
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'relative',
            display: ['block', 'none'],
          }}
          src={`https:${mobileVideo?.file?.url}`}
          autoPlay
          playsInline
          loop
          muted
          controls={false}
        />
      </div>
      {/* This one subverts the pattern of having these dividers at the top level because of the transparency & video overlap */}
      {/* Would be nice to refactor this into one component but right now I'm not entirely sure how that fits with all designs */}
      <WigglySectionDivider
        forwardSx={{
          display: ['none', 'flex'],
          position: 'absolute',
          bottom: 0,
        }}
        topColor="transparent"
        bottomColor={theme.colors.japaneseLaurel}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'absolute',
          bottom: '-4px',
        }}
        topColor="transparent"
        bottomColor={theme.colors.japaneseLaurel}
      />
    </Container>
  )
}

export default HeroSection
