/** @jsx jsx */
import { Link } from 'gatsby'

import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'
import ImgLoader from '~/components/Generic/ImgLoader'
import { useLocation } from '@reach/router';
import { toURL } from '~/utils/intl/localePrefix';
const ArticleCard = ({ article, ignoreFeatured = false }) => {
  const {
    title,
    slug,
    description,
    image,
    publishDate,
    tags,
    isFeatured = false
  } = article
  const featured = isFeatured && !ignoreFeatured
  const location = useLocation();
  return (
    <Link
      to={toURL(location && location.pathname, `/blog/${slug}`)}
      data-tag={tags ? tags[0] : null}
      sx={{
        backgroundColor: 'waxFlower',
        borderRadius: '4.5px',
        overflow: 'hidden',
        border: '2px solid',
        borderColor: 'blazeOrange',
        color: 'blazeOrange',
        gridColumn: ['auto', null, featured ? '1 / 4' : 'auto'],
        minHeight: [null, null, featured ? '542px' : null],
        '&:hover': {
          '.gatsby-image-wrapper': {
            overflow: 'visible !important'
          },
          img: {
            transformOrigin: 'bottom',
            transform: 'scale(1.1) translateY(-0%)'
          }
        }
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', null, featured ? 'row-reverse' : 'column'],
          height: '100%'
        }}
      >
        {image && (
          <Box
            sx={{
              minWidth: ['100%', null, featured ? '50.89%' : '100%'],
              '.gatsby-image-wrapper > div': { pb: '90.724% !important' },

              borderBottom: [
                '2px solid',
                null,
                featured ? 'none' : '2px solid'
              ],
              borderLeft: [null, null, featured ? '2px solid' : null],
              borderColor: 'blazeOrange'
            }}
          >
            <ImgLoader
              image={image}
              forwardSx={{
                height: '100%',
                py: ['2px']
              }}
              imgStyle={{ objectPosition: 'top' }}
            />
          </Box>
        )}
        <Flex
          sx={{
            padding: ['15px', null, featured ? '30px' : '15px'],
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: [null, null, featured ? 'center' : null],
            flex: [1, null, featured ? null : 1],
            textAlign: ['left', null, featured ? 'center' : 'left'],
            backgroundColor: 'waxFlower'
          }}
        >
          {(publishDate || tags) && (
            <Flex
              sx={{
                width: '100%',
                justifyContent: [
                  'space-between',
                  null,
                  featured ? 'center' : 'space-between'
                ],
                flexWrap: 'wrap',
                fontSize: 0,
                fontWeight: 'extraBold'
              }}
            >
              {tags && <Text>{tags[0]}</Text>}
              {tags && publishDate && featured && (
                <span sx={{ mx: '4px', display: ['none', null, 'inline'] }}>
                  |
                </span>
              )}
              {publishDate && (
                <Text>
                  {ignoreFeatured ? publishDate.split('|')[1] : publishDate}
                </Text>
              )}
            </Flex>
          )}
          <Heading
            as="h2"
            sx={{
              width: '100%',
              mt: '15px',
              mb: ['37px', null, featured ? '15px' : '37px'],
              fontSize: ['22px', null, featured ? '36px !important' : null]
            }}
          >
            {title}
          </Heading>
          {description && (
            <Text
              sx={{
                width: '100%',
                maxWidth: [null, null, featured ? '417px' : null],
                variant: 'text.body',
                lineHeight: '125%',
                mb: '30px',
                p: {
                  m: 0
                }
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html
                }}
              />
            </Text>
          )}
          <Text
            as="span"
            sx={{
              mt: ['auto', null, featured ? 0 : 'auto'],
              fontSize: 0,
              fontWeight: 'extraBold',
              textAlign: 'center',
              pb: '2px',
              borderBottom: '2px solid',
              display: 'inline-block'
            }}
          >
            READ MORE
          </Text>
        </Flex>
      </Flex>
    </Link>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default ArticleCard
