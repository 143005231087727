/** @jsx jsx */
import Img from 'gatsby-image'
import { Grid, jsx, Flex, Heading, Text, useThemeUI } from 'theme-ui'
import BouncyIngredientIcon from '~/components/Home/BouncyIngredientIcon'
import FadeInWrapper from '~/components/Generic/FadeInWrapper'

function NoodleBowlSection({
  page: {
    valuePropImage: valuePropImages,
    bowlImage,
    valuePropSubheading: noodleBowlSubheading,
    valuePropHeading: noodleBowlHeading
  }
}) {
  const { theme } = useThemeUI()
  //slice array of bouncing Icons
  const leftBouncingIcons = valuePropImages.valuePropImage.slice(0, 3)
  const rightBouncingIcons = valuePropImages.valuePropImage.slice(3, 6)
  return (
    <Flex
      sx={{
        backgroundColor: theme.colors.japaneseLaurel,
        position: 'relative',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: theme.colors.robinsEggBlue,
        px: [0, 50],
        pb: ['100px', null, '192px']
      }}
    >
      <FadeInWrapper>
        <Heading
          sx={{
            width: ['85%', 'auto'],
            maxWidth: 800,
            pt: 96,
            mx: 'auto',
            mb: 12
          }}
          variant="text.h2"
        >
          {noodleBowlHeading}
        </Heading>
      </FadeInWrapper>
      <FadeInWrapper>
        <Text
          as="p"
          sx={{
            width: ['85%', 'auto'],
            maxWidth: 680,
            marginBottom: 60,
            fontSize: 18,
            px: '10px',
            mx: 'auto'
          }}
        >
          {noodleBowlSubheading}
        </Text>
      </FadeInWrapper>
      <Grid
        columns={[
          1,
          ' minmax(120px,200px)  minmax(0px,700px) minmax(120px,200px)'
        ]}
      >
        <Grid
          columns={[3, 1]}
          sx={{
            width: ['90%', '100%'],
            maxWidth: ['100%', 200],
            justifySelf: ['center', 'end']
          }}
        >
          {leftBouncingIcons.map((imgObj, i) => {
            return (
              <BouncyIngredientIcon
                justifySelf={i === 1 ? 'start' : 'end'}
                imgObj={imgObj}
                forwardSx={{ width: ['auto', 120], height: ['150px', 'auto'] }}
                key={i}
              />
            )
          })}
        </Grid>
        {/* This container lets us do an overflow of the noodlebowl */}
        <div
          sx={{
            width: ['85%', '100%'],
            justifySelf: 'center'
          }}
        >
          <Img fluid={bowlImage.fluid}></Img>
        </div>
        <Grid
          columns={[3, 1]}
          sx={{
            width: ['90%', '100%'],
            maxWidth: ['100%', 200],
            justifySelf: ['center']
          }}
        >
          {rightBouncingIcons.map((imgObj, i) => {
            return (
              <BouncyIngredientIcon
                justifySelf={i === 1 ? 'end' : 'start'}
                imgObj={imgObj}
                forwardSx={{ width: ['auto', 120], height: ['150px', 'auto'] }}
                key={i}
              />
            )
          })}
        </Grid>
      </Grid>
    </Flex>
  )
}

export default NoodleBowlSection
