/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'

export function MobileWigglySectionDivider({
  topColor,
  forwardSx,
  bottomColor,
  small = false,
  invert = false,
  id,
}) {
  return (
    <Flex
      sx={{
        display: ['flex', 'none'],
        width: '102vw',
        flexDirection: 'column',
        left: '-4px',
        right: '-4px',
        background: invert ? bottomColor : topColor,
        translate: 'transform3d(0,0,1px)',
        isolation: 'isolate',
        sizing: 'content-box',
        ...forwardSx,
      }}
    >
      {small ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          id={id}
          viewBox="0 0 375 69"
        >
          <path
            d="M-102 38c8-2 22-4 38-5 29 0 40 12 73 13 32 0 50-12 82-13 57-1 55 35 110 36 61 1 84-46 139-44 40 1 62 21 96 21 19 0 32-5 41-8V0h-579v38Z"
            fill={`${invert ? topColor : bottomColor}`}
          />
        </svg>
      ) : (
        <svg id={id} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376 62">
          <path
            d="M375 37c-5 3-14 6-25 7-19 0-26-14-47-16-21-1-25 12-46 13-37 2-47-40-83-41-39-1-55 47-91 44-26-3-34-20-57-19-12 0-20 6-26 12v27h375V37Z"
            fill={`${invert ? topColor : bottomColor}`}
          />
        </svg>
      )}
    </Flex>
  )
}
