/* @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { jsx, Heading, Text, Flex, Grid, useThemeUI } from 'theme-ui'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { ArrowButton } from './ArrowButton'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import FadeInWrapper from '../../../Generic/FadeInWrapper'

const smallTextStyle = {
  fontWeight: 700,
  letterSpacing: '0.5px',
  lineHeight: '14px',
  fontSize: '14px',
  color: 'governorBay',
}

export const TestimonialCarousel = ({
  page: { quoteCards: quotes, quoteSectionTitle: title },
}) => {
  //#region Hooks
  const [isMobile, setIsMobile] = useState(false)
  let swiperRef = useRef(null)
  const matchMedia = useRef(
    typeof window !== 'undefined'
      ? window.matchMedia('(max-width: 968px)')
      : null,
  )

  useEffect(() => {
    if (!matchMedia.current) return

    matchMedia.current.addEventListener('change', handleResize)
    setIsMobile(matchMedia.current.matches)

    return () => {
      matchMedia.current.removeEventListener('change', handleResize)
    }
  }, [matchMedia.current])
  //#endregion

  //#region Methods
  const handleResize = () => {
    setIsMobile(matchMedia.current.matches)
  }

  const handleOnSwiper = swiperInstance => {
    swiperRef.current = swiperInstance
  }

  const handleNext = () => {
    if (!swiperRef.current) return
    swiperRef.current.slideNext()
  }

  const handlePrev = () => {
    if (!swiperRef.current) return
    swiperRef.current.slidePrev()
  }
  //#endregion

  const centerTextOnMobile = {
    textAlign: isMobile ? 'center' : 'left',
  }
  const { theme } = useThemeUI()

  return (
    <div
      sx={{
        background: theme.colors.schoolBusYellow,
        width: '100%',
        paddingY: [64, 80],
        paddingX: ['12px', 39],
        fontFamily: 'secondary',
        position: 'relative',
      }}
    >
      <FadeInWrapper>
        <Heading
          sx={{
            fontWeight: 900,
            fontSize: ['2.5rem', '3.7rem'],
            lineHeight: 1.2,
            color: 'governorBay',
            textAlign: 'center',
            fontFamily: 'heading',
            textTransform: 'uppercase',
            mb: '34px',
          }}
        >
          {title}
        </Heading>
      </FadeInWrapper>
      <Swiper
        grabCursor
        sx={{ cursor: 'grab' }}
        slidesPerView="auto"
        loop
        onSwiper={handleOnSwiper}
      >
        <ArrowButton
          forwardSx={{ mt: ['2rem', 0] }}
          invert
          onClick={handlePrev}
          isMobile={isMobile}
        />
        <ArrowButton
          forwardSx={{ mt: ['2rem', 0] }}
          onClick={handleNext}
          isMobile={isMobile}
        />
        {quotes.map(quote => (
          <SwiperSlide key={quote.id}>
            <Grid
              sx={{
                gridTemplateColumns: isMobile ? '1fr' : '25% 75%',
                width: '80%',
                maxWidth: 680,
                placeItems: 'center',
                justifyItems: 'center',
                mx: 'auto',
                columnGap: 44,
              }}
            >
              {!isMobile && <CarouselImage quote={quote} />}
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  height: '100%',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <FadeInWrapper>
                  <Text
                    as="p"
                    sx={{
                      lineHeight: 1.2,
                      fontSize: ['18px', '24px'],
                      maxWidth: [315, 450],
                      minHeight: ['90px', '115px', 'none'],
                      display: 'flex',
                      alignItems: 'center',
                      color: 'governorBay',
                      mb: '32px',
                      fontWeight: 400,
                      flex: 1,
                      fontFamily: 'body',
                      ...centerTextOnMobile,
                    }}
                  >
                    {quote.quote}
                  </Text>
                </FadeInWrapper>
                {isMobile && <CarouselImage quote={quote} />}
                <FadeInWrapper>
                  <Text
                    as="p"
                    sx={{
                      ...smallTextStyle,
                      ...centerTextOnMobile,
                      mt: [32, 0],
                      textTransform: 'uppercase',
                      letterSpacing: 0.5,
                      mb: ['8px', '4px'],
                    }}
                  >
                    {quote.attributionName}
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper>
                  <Text
                    as="p"
                    sx={{ ...smallTextStyle, ...centerTextOnMobile }}
                  >
                    {quote.attributionTitle}
                  </Text>
                </FadeInWrapper>
              </Flex>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
      <img
        src="/images/trophy.png"
        alt="trophy"
        sx={{
          position: 'absolute',
          bottom: ['-130px'],
          zIndex: 2,
          right: '-96px',
        }}
      />
    </div>
  )
}

TestimonialCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributionName: PropTypes.string.isRequired,
      attributionTitle: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
      photo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
          srcWebp: PropTypes.string.isRequired,
          sizes: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        file: PropTypes.shape({
          details: PropTypes.shape({
            image: PropTypes.shape({
              width: PropTypes.number.isRequired,
            }),
          }),
        }),
      }),
    }),
  ),
}

function CarouselImage({ quote }) {
  return (
    <div sx={{ width: '100%', maxWidth: '154px' }}>
      <FadeInWrapper>
        <Img
          fluid={quote.photo.fluid}
          alt={quote.photo.title}
          imgStyle={{
            width: '100%',
            height: 'auto',
            borderRadius: '50%',
            maxWidth: '150px',
            maxHeight: '150px',
          }}
        />
      </FadeInWrapper>
    </div>
  )
}
