/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, useColorMode, useThemeUI } from 'theme-ui'
import { WigglySectionDivider } from '../Generic/SectionDivider/WigglySectionDivider'
import { MobileWigglySectionDivider } from '~/components/Generic/SectionDivider/MobileWigglySectionDivider'
import { TestimonialCarousel } from '../Content/Section/TestimonialCarousel'
import PageHighlightsSection from '~/components/Home/PageHighlightsSection'
import NoodleBowlSection from '~/components/Home/NoodleBowlSection'
import ArticleHighlightSection from '~/components/Home/ArticleHighlightSection'
import RedeemGiftCardModal from '~/components/Home/RedeemGiftCardModal'
import { ProductGrid } from '~/components/Product/Grid/ProductGrid'
import HeroSection from '~/components/Home/HeroSection'
import PressSection from '~/components/Home/PressSection/index'
import ProductHighlightSection from './ProductHighlightSection'
import Marquee from '../Generic/Marquee'
import GeneralHighlightSection from './GeneralHighlightSection'
import VideoTestimonialsSection from './VideoTestimonials'

const HomePage = ({ page }) => {
  const { productGrid, node_locale } = page
  const [giftModalOpen, setGiftModalOpen] = useState(false)
  const [colorMode, setColorMode] = useColorMode('Home')
  const { theme } = useThemeUI()

  const homePageButtonStyle = {
    color: theme.colors.governorBay,
    backgroundColor: theme.colors.schoolBusYellow,
  }

  useEffect(() => {
    setColorMode('Home')
  }, [])

  useEffect(() => {
    const checkGiftCard = async () => {
      if (typeof window !== `undefined`) {
        // going to need to change this for intl stuff
        const giftCardRoute = window.location.pathname.split('/')[1]
        if (giftCardRoute === 'redeemGiftCard') {
          // TODO get this non scrolling background to work without a setTimeout
          setTimeout(() => {
            document.body.style.overflow = 'hidden'
          }, 2000)
          setGiftModalOpen(true)
        }
      }
    }
    checkGiftCard()
  }, [])

  return (
    // This -128 container is to compensate for the transparency of the NavBar
    <div
      sx={{
        position: 'relative',
        top: '-128px',
        overflow: 'hidden',
        maxHeight: '100%',
      }}
    >
      {giftModalOpen && (
        <RedeemGiftCardModal setGiftModalOpen={setGiftModalOpen} />
      )}
      <HeroSection page={page} />
      <PressSection page={page} />
      <WigglySectionDivider
        topColor={theme.colors.japaneseLaurel}
        bottomColor={theme.colors.frenchPass}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-1px',
        }}
        small
        invert
        topColor={theme.colors.japaneseLaurel}
        bottomColor={theme.colors.frenchPass}
      />
      <ProductGrid data={productGrid} node_locale={node_locale} />
      <WigglySectionDivider
        invert
        topColor={theme.colors.frenchPass}
        bottomColor={theme.colors.robinsEggBlue}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
        }}
        small
        invert
        topColor={theme.colors.frenchPass}
        bottomColor={theme.colors.robinsEggBlue}
      />
      <Marquee page={page} />
      <WigglySectionDivider
        invert
        topColor={theme.colors.robinsEggBlue}
        bottomColor={theme.colors.governorBay}
        small
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
        }}
        small
        invert
        topColor={theme.colors.robinsEggBlue}
        bottomColor={theme.colors.governorBay}
      />
      <ProductHighlightSection page={page} />
      <WigglySectionDivider
        invert
        topColor={theme.colors.governorBay}
        bottomColor={theme.colors.japaneseLaurel}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
        }}
        topColor={theme.colors.governorBay}
        bottomColor={theme.colors.japaneseLaurel}
      />
      <NoodleBowlSection page={page} />
      <WigglySectionDivider
        topColor={theme.colors.japaneseLaurel}
        bottomColor={theme.colors.schoolBusYellow}
        invert
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
        }}
        topColor={theme.colors.japaneseLaurel}
        bottomColor={theme.colors.schoolBusYellow}
      />
      <TestimonialCarousel page={page} />
      <WigglySectionDivider
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.pink}
        forwardSx={{
          mt: -1,
        }}
        small
      />
      <PageHighlightsSection
        homePageButtonStyle={homePageButtonStyle}
        page={page}
      />
      <WigglySectionDivider
        topColor={theme.colors.pink}
        bottomColor={theme.colors.sunsetOrange}
        invert
      />
      <VideoTestimonialsSection page={page} />
      <WigglySectionDivider
        topColor={theme.colors.sunsetOrange}
        bottomColor={theme.colors.schoolBusYellow}
        forwardSx={{
          display: ['none', 'flex'],
          mt: -1,
        }}
        small
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginBottom: '-5px',
        }}
        topColor={theme.colors.sunsetOrange}
        bottomColor={theme.colors.schoolBusYellow}
      />
      <GeneralHighlightSection page={page} />
      <WigglySectionDivider
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.sunsetOrange}
        forwardSx={{
          display: ['none', 'flex'],
          mt: -1,
        }}
        small
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginBottom: '5px',
        }}
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.sunsetOrange}
      />
      <ArticleHighlightSection
        page={page}
        homePageButtonStyle={homePageButtonStyle}
      />
    </div>
  )
}

export default HomePage
