/** @jsx jsx */

import { jsx, Box, Grid, Button } from 'theme-ui'
import { Heading } from 'theme-ui'
import Img from 'gatsby-image'
import { LinkWrapper } from './LinkWrapper'
import FadeInWrapper from '~/components/Generic/FadeInWrapper'
import { keyframes } from '@emotion/react'

const butterfly = keyframes({
  '0%': {
    backgroundImage: "url('butterfly-1-min.png')",
  },
  '1%': {
    backgroundImage: "url('butterfly-2-min.png')",
  },
  '2%': {
    backgroundImage: "url('butterfly-3-min.png')",
  },
  '4%': {
    backgroundImage: "url('butterfly-2-min.png')",
  },
  '8%': {
    backgroundImage: "url('butterfly-1-min.png')",
  },
  '10%': {
    backgroundImage: "url('butterfly-1-min.png')",
  },
  '11%': {
    backgroundImage: "url('butterfly-2-min.png')",
  },
  '12%': {
    backgroundImage: "url('butterfly-3-min.png')",
  },
  '14%': {
    backgroundImage: "url('butterfly-2-min.png')",
  },
  '18%': {
    backgroundImage: "url('butterfly-1-min.png')",
  },

  '40%': {
    backgroundImage: "url('butterfly-1-min.png')",
  },
  '42%': {
    backgroundImage: "url('butterfly-2-min.png')",
  },
  '44%': {
    backgroundImage: "url('butterfly-3-min.png')",
  },
  '46%': {
    backgroundImage: "url('butterfly-2-min.png')",
  },
  '50%': {
    backgroundImage: "url('butterfly-1-min.png')",
  },
})

function ImageAndTextSection({
  containerStyle,
  cta = {},
  buttonStyle,
  headingText,
  subHeadingText,
  showButterfly,
  fluid,
}) {
  return (
    <div sx={{ ...containerStyle }}>
      <Grid
        sx={{
          position: 'relative',
          padding: [0, 50],
          maxWidth: 1440,
          margin: 'auto',
          width: ['400px', 'unset'],
          textAlign: ['center', 'left'],
          alignItems: 'end',
        }}
        columns={[1, 'repeat(2,minmax(0,1fr))']}
      >
        <div
          sx={{
            alignSelf: 'center',
          }}
        >
          <FadeInWrapper>
            <Heading
              variant="text.h1"
              sx={{
                width: '100%',
              }}
            >
              {headingText}
            </Heading>
          </FadeInWrapper>
          <FadeInWrapper>
            <div
              sx={{
                marginY: 30,
                width: ['100%'],
                fontSize: 18,
              }}
            >
              {subHeadingText}
            </div>
          </FadeInWrapper>

          {cta && (
            <LinkWrapper
              slug={cta?.page?.slug}
              contenttype={cta?.page?.__typename}
            >
              <FadeInWrapper>
                <Button
                  sx={{
                    ...buttonStyle,
                  }}
                >
                  {cta?.text}
                </Button>
              </FadeInWrapper>
            </LinkWrapper>
          )}
        </div>
        {fluid ? (
          <FadeInWrapper threshold={0.5}>
            <div
              sx={{
                width: '100%',
                maxWidth: 500,
                overflowX: !showButterfly && 'hidden',
                position: showButterfly && 'relative',
              }}
            >
              {showButterfly && (
                <Box
                  sx={{
                    zIndex: 1,
                    pointerEvents: 'none',
                    position: 'absolute',
                    width: ['80px', '90px'],
                    height: ['80px', '90px'],
                    top: ['-37px', '-43px'],
                    left: '52%',
                    transform: 'translateX(-50%) scale(-1, 1)',
                    backgroundSize: 'cover',
                    backgroundImage: 'url(butterfly-1-min.png)',
                    animation: `5s ${butterfly} infinite`,
                  }}
                ></Box>
              )}
              <Img fluid={fluid}></Img>
            </div>
          </FadeInWrapper>
        ) : null}
      </Grid>
    </div>
  )
}
export default ImageAndTextSection
