/** @jsx jsx */
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Box, jsx, Button } from 'theme-ui'
import useWindowSize from '~/hooks/components/use-window-size'
import ProgressBar from './ProgressBar'
import PlayIcon from '~/assets/images/icons/video-player.svg'

const VideoPlayer = ({
  url,
  loop,
  forwardSx,
  isActive,
  isVisible,
  isPlaying,
  setIsPlaying,
}) => {
  const videoRef = useRef(null)

  const [isRendered, setisRendered] = useState(false)
  const [metadataLoaded, setMetadataLoaded] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [isMuted, setIsMuted] = useState(false)
  const [showPlayButton, setShowPlayButton] = useState(false)

  const { width } = useWindowSize()

  const togglePlaying = () => {
    if (!videoRef?.current) return
    if (videoRef.current.paused) {
      const promise = videoRef.current.play()
      if (promise !== undefined) {
        promise
          .then(() => {
            setShowPlayButton(false)
          })
          .catch(e => {
            console.error('Video Player Error, ', e)
            setShowPlayButton(true)
          })
      }
    } else {
      videoRef.current.pause()
    }
  }

  // Intersection observer for pausing once video is off screen
  useEffect(() => {
    // The reference for cleanup
    const ref = videoRef
    if (!videoRef?.current) return

    if (loop && ref?.current) {
      ref.current.play()
    }

    // Use intersection observer to pause the video when it goes off screen.
    if (ref.current && window.IntersectionObserver) {
      const visibleThreshold = loop ? 0 : 0.5

      const options = {
        root: null, // viewport for checking target visibility - we use 'null' to target the window.
        rootMargin: '0px',
        threshold: visibleThreshold, // if < 50% of the element is viewable, triggers callback.
      }

      const playBackObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio < visibleThreshold) {
            ref.current.pause()
            setIsMuted(true)
          }
        })
      }, options)

      playBackObserver.observe(ref.current)

      // cleanup
      return () => {
        if (ref.current) {
          playBackObserver.unobserve(ref.current)
        }
        playBackObserver.disconnect()
      }
    }
  }, [isRendered])

  // On Mount
  useEffect(() => {
    setisRendered(true)
  }, [])

  // Autoplay for carousel (mobile and tablet only)
  useEffect(() => {
    if (width > 1024) return

    if (isActive && isVisible) {
      setIsPlaying(true)
      setIsMuted(false)
    } else {
      setIsPlaying(false)
      setIsMuted(true)
    }
  }, [isActive])

  // Autoplay on hover, isPlaying is set in parent component
  useEffect(() => {
    if (!videoRef.current) return
    if (isPlaying) {
      videoRef.current.play().catch(error => {
        setShowPlayButton(true)
        console.error('video autoplay error', error)
      })
    } else {
      videoRef.current.pause()
      setShowPlayButton(false)
    }
  }, [isPlaying])

  const handleTimeUpdate = ({ target: { currentTime } }) => {
    setCurrentTime(currentTime)
  }

  if (!isRendered) {
    return null
  }

  return (
    <Box
      sx={{
        width: '100%',
        aspectRatio: '9/16',
        borderRadius: '1.5rem',
        overflow: 'hidden',
        maxHeight: ['26rem', null, '36rem'],
        m: 0,
        ...forwardSx,
      }}
    >
      <video
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        ref={videoRef}
        playsInline
        muted={isMuted}
        autoPlay={loop}
        loop={loop}
        controls={false}
        controlsList="nodownload"
        onClick={togglePlaying}
        onTimeUpdate={handleTimeUpdate}
        onVolumeChange={() => setIsMuted(false)}
        onEnded={() => setIsPlaying(false)}
        onLoadedMetadata={() => setMetadataLoaded(true)}
        preload="metadata"
      >
        <source src={`${url}#t=0.1`} type="video/mp4" />
      </video>
      {showPlayButton && (
        <Button
          variant="bare"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            color: 'schoolBusYellow',
            path: {
              fill: 'goodlesRed',
            },
          }}
          onClick={togglePlaying}
        >
          <PlayIcon />
        </Button>
      )}
      <ProgressBar
        ref={videoRef}
        currentTime={currentTime}
        setCurrentTime={setCurrentTime}
      />
    </Box>
  )
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
}

export default VideoPlayer
